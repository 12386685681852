import { sampleCorrelation } from 'simple-statistics'
import { definitionForQuestion } from '../../../controller/src/questions'
import {
	IClientState,
	IDataPoint,
	IProfile
} from '../../../controller/src/types'
import * as Utils from './utils'

export interface IVotesByQuestion {
	[question: string]: string[]
}

export interface IPointsByClient {
	[id: string]: IDataPointsWithVotes
}

export interface IClientPoints {
	_id: string
	key: IDataPoint
	points: number
}

export type IDataPointsWithVotes = { [dataPoint in IDataPoint]?: number }

export interface IAlgorithmResult {
	client: IClientState
	points?: IDataPointsWithVotes
}

export interface ICorrelationResult {
	key: string
	correlation: number
}

export function clientPointsToAlgorithmResult(
	state: IClientState[],
	clientPoints: IClientPoints[]
): IAlgorithmResult[] {
	return clientPoints.map(c => ({
		client: Utils.clientWithId(c._id, state),
		points: {
			[c.key]: c.points
		}
	}))
}

export function resultsByQuestionFromState(state: IClientState[]) {
	const results: IVotesByQuestion = {}

	state.forEach(client => {
		const history = client.history || {}
		Object.keys(history).forEach(key => {
			const round = history[key]
			round.forEach(({ question, answer }) => {
				if (answer) {
					const c = results[question] || []
					results[question] = [...c, answer.value]
				}
			})
		})
	})

	return results
}

export function pointsFromVotesByQuestions(votesByQuestion: IVotesByQuestion) {
	const result: IPointsByClient = {}

	Object.keys(votesByQuestion).forEach(question => {
		const answers = votesByQuestion[question]
		const definition = definitionForQuestion(question)
		if (!definition) {
			return
		}
		answers.forEach(answer => {
			if (!result.hasOwnProperty(answer)) {
				result[answer] = {}
			}
			definition.dataPoints.forEach(d => {
				const value = result[answer][d] || 0
				result[answer][d] = value + 1
			})
		})
	})

	return result
}

// export function pointsForDataPoint(
// 	clients: IAlgorithmResult,
// 	key: IDataPoint
// ): IAlgorithmResult[] {
// 	return Object.keys(clients).map(id => {
// 		const points = clients[id]
// 		return { _id: id, key, points: points[key] }
// 	})
// }

export function idAndPersonalQuestion(
	state: IClientState[],
	key: keyof IProfile
): Array<IClientState & IDataPointsWithVotes> {
	return Utils.onlyParticipating(state)
		.filter(c => !!c.profile)
		.map(c => {
			return {
				...c,
				[key]: c.profile[key]
			}
		})
}

export function idsSortedByDataPoint(
	state: IClientState[],
	dataPoint: IDataPoint
) {
	const clients = clientsSortedByDataPoint(state, dataPoint)
	return clients.map(({ client }) => client._id)
}

export function clientsSortedByDataPoint(
	state: IClientState[],
	dataPoint: IDataPoint
) {
	const c = stateToPointsByClient(state)
	return Utils.sortByPoint(c, dataPoint)
}

export function answerDuration(client: IClientState) {
	const answerDurations = client.answerDurations || {}
	return Object.keys(answerDurations).reduce((pv, cv) => {
		return pv + answerDurations[cv]
	}, 0)
}

export function averageOrientation(client: IClientState) {
	const answerOrientation = client.answerOrientation || {}
	const arr = Object.keys(answerOrientation)
	const orientationSum = arr.reduce((pv, cv) => {
		return pv + answerOrientation[cv]
	}, 0)
	return arr.length > 0 ? orientationSum / arr.length : 0
}

export function twoMostSimilar(state: IClientState[]): IClientState[] {
	const t = stateToPointsByClient(state)

	const b = t.map(result => {
		const withoutSelf = t.filter(r => r.client._id !== result.client._id)
		const similarity = withoutSelf.map(otherResult => {
			const otherDataPoints = Object.keys(otherResult.points)
			const distances = otherDataPoints.map(dataPointKey => {
				const dataPoint = result.points[dataPointKey] || 0
				const otherDataPoint = otherResult.points[dataPointKey]
				const distance = Math.abs(dataPoint - otherDataPoint)
				return { key: dataPointKey, distance }
			})
			const summedDistances = distances.reduce((pv, cv) => {
				return pv + cv.distance
			}, 0)
			return { id: otherResult.client._id, summedDistances }
		})
		similarity.sort((aa, bb) => aa.summedDistances - bb.summedDistances)
		const mostSimilar = similarity[0]
		return {
			id: result.client._id,
			mostSimilar: mostSimilar.id,
			distance: mostSimilar.summedDistances
		}
	})
	b.sort((aa, bb) => aa.distance - bb.distance)
	const bestMatch = b[0]
	const first = state.find(c => c._id === bestMatch.id)
	const second = state.find(c => c._id === bestMatch.mostSimilar)
	return [first, second]
}

export function stateToPointsByClient(
	state: IClientState[]
): IAlgorithmResult[] {
	const participating = Utils.onlyParticipating(state)
	const byQuestion = resultsByQuestionFromState(participating)
	const questionPointsByClient = pointsFromVotesByQuestions(byQuestion)
	return Object.keys(questionPointsByClient).map(id => {
		const client = Utils.clientWithId(id, state)
		const points = questionPointsByClient[id]
		points.secretive = averageOrientation(client)
		points.influence = influence(points)
		points.slowness = answerDuration(client)
		return { client, points }
	})
}

function influence(points: IDataPointsWithVotes) {
	const unique = points.unique || 0
	const trust = points.trust || 0
	return unique + trust
}

export function clientsWithPassword(state: IClientState[], password: string) {
	return state.filter(client => {
		return client.password === password
	})
}

export function findPatternsInAnswers(state: IClientState[]) {
	const participating = Utils.onlyParticipating(state)
	const cheatingProbabilities = participating.map(client => {
		const history = client.history || {}
		const rounds = Object.keys(history).map((k, i) => {
			const questions = history[k]
			const aduration = client.answerDurations || {}
			const answerDurationsKeys = Object.keys(aduration)
			const roundDuration = answerDuration[answerDurationsKeys[i]]
			const answers = questions.map(question => {
				const answer = question.answer ? question.answer.value : 'none'
				const optionValues = question.options.map(o => o.value)
				const index = optionValues.indexOf(answer)
				if (index === -1) {
					console.error(
						`Answer ${answer} could not be found in options`
					)
				}
				return index
			})
			const hasPattern =
				Utils.numberAlternates(answers) || Utils.numbersRepeat(answers)
			return { hasPattern, duration: roundDuration }
		})
		const cheatingProbability = rounds.reduce((pv, cv) => {
			return cv.hasPattern ? pv + 1 / rounds.length : pv
		}, 0)
		const duration = answerDuration(client)
		return {
			_id: client._id,
			name: client.username,
			cheatingProbability,
			rounds: JSON.stringify(rounds),
			duration
		}
	})
	return cheatingProbabilities
}

export function correlationsWithDataPoint(
	state: IClientState[],
	dataPoint: IDataPoint
): ICorrelationResult[] {
	const clientsWithPoints = stateToPointsByClient(state)
	const dataPointScores = Object.keys(clientsWithPoints).map(
		k => clientsWithPoints[k].points[dataPoint] || 0
	)

	const otherScores = Object.keys(clientsWithPoints).map(k => {
		const { [dataPoint]: point, ...rest } = clientsWithPoints[k].points
		return rest
	})

	const otherKeys = Object.keys(
		otherScores.reduce((pv, cv) => ({ ...pv, ...cv }), {})
	)

	return otherKeys.map(key => {
		const pointsForKey = Object.keys(clientsWithPoints).map(c => {
			const client = clientsWithPoints[c]
			return client.points[key] || 0
		})
		const correlation = sampleCorrelation(dataPointScores, pointsForKey)
		return { key, correlation }
	})
}
