import { AppAction } from './app/actions'
import { FirebaseAction } from './firebase/actions'

export enum AppActionTypes {
	SET_CONNECTION_STATE = 'SET_CONNECTION_STATE',
	SET_REFRESHING_STATE = 'SET_REFRESHING_STATE'
}

export enum FirebaseActionTypes {
	UPDATE = 'UPDATE',
	SET_IMAGE_URL = 'SET_IMAGE_URL',
	SET_MATCH_QUESTIONS_FOR_ID = 'SET_MATCH_QUESTIONS_FOR_ID',
	SET_STATE_OF_PARTICIPANTS = 'SET_STATE_OF_PARTICIPANTS',
	SET_POSITION_FROM_SORTED_IDS = 'SET_POSITION_FROM_SORTED_IDS',
	SET_POSITION_FOR_ID = 'SET_POSITION_FOR_ID',
	SET_STATE_KEY_FOR_ID = 'SET_STATE_KEY_FOR_ID',
	REFRESH_LOCAL_STORE = 'REFRESH_LOCAL_STORE',
	UPDATE_FIREBASE_FROM_LOCAL_STORE = 'UPDATE_FIREBASE_FROM_LOCAL_STORE'
}

export const ActionTypes = { ...FirebaseActionTypes, ...AppActionTypes }
export type ActionTypes = FirebaseActionTypes | AppActionTypes
export type Action = FirebaseAction | AppAction

export function createAction<T extends { type: ActionTypes }>(d: T): T {
	return d
}
