import * as React from 'react'
import { twoMostSimilar } from '../../analytics/algorithms'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'
import styles from './styles.module.scss'

const AdamAndEve: React.SFC<ISharedStepProps> = (props) => {
	const adamAndEve = twoMostSimilar(props.state)
	const sendToPhonesAction = () => {
		props.actions.setStateOfParticipantsTo(
			adamAndEve,
			'purple_screen',
			'none'
		)
		props.actions.updateFirebaseAction()
	}

	return (
		<div className={styles.noTableSlide}>
			<h1>Romeo & Julia</h1>
			<ul>
				{adamAndEve.map((r, index) => {
					const { username } = r || { username: 'Unknown' }
					return <li key={username + index}>{username}</li>
				})}
			</ul>
			<Button size="lg" onClick={sendToPhonesAction}>
				Give Purple screen
			</Button>
		</div>
	)
}

export default AdamAndEve
