import {
	DataStateType,
	IClientState
} from '../../../../controller/src/types'

import { createAction, FirebaseActionTypes } from '../actions'

export function createUpdateAction(data: IClientState[]) {
	return createAction({ type: FirebaseActionTypes.UPDATE, data })
}

export function createUpdateFirebaseAction() {
	return createAction({
		type: FirebaseActionTypes.UPDATE_FIREBASE_FROM_LOCAL_STORE
	})
}

export function createSetStateForIdKeyAction(
	id: string,
	state: DataStateType,
	others?: DataStateType
) {
	return createAction({
		type: FirebaseActionTypes.SET_STATE_KEY_FOR_ID,
		id,
		state,
		others
	})
}

export function createSetPositionForId(id: string, position: number) {
	return createAction({
		type: FirebaseActionTypes.SET_POSITION_FOR_ID,
		id,
		position
	})
}

export function createSetMatchQuestionsForId(
	id: string,
	matchQuestions: string[]
) {
	return createAction({
		type: FirebaseActionTypes.SET_MATCH_QUESTIONS_FOR_ID,
		id,
		matchQuestions
	})
}

export function createSetImageURLAction(url: string) {
	return createAction({
		type: FirebaseActionTypes.SET_IMAGE_URL,
		url
	})
}

export function createSetPositionsFromSortedIdsAction(sortedIds: string[]) {
	return createAction({
		type: FirebaseActionTypes.SET_POSITION_FROM_SORTED_IDS,
		sortedIds
	})
}

export function createSetStateOfParticipantsTo(
	participants: Array<Partial<IClientState> | undefined>,
	state: DataStateType,
	others?: DataStateType
) {
	return createAction({
		type: FirebaseActionTypes.SET_STATE_OF_PARTICIPANTS,
		participants,
		state,
		others
	})
}

export function createRefreshLocalStoreAction() {
	return createAction({ type: FirebaseActionTypes.REFRESH_LOCAL_STORE })
}

export type FirebaseAction = ReturnType<
	| typeof createUpdateAction
	| typeof createSetStateForIdKeyAction
	| typeof createSetStateOfParticipantsTo
	| typeof createSetPositionsFromSortedIdsAction
	| typeof createSetPositionForId
	| typeof createSetImageURLAction
	| typeof createSetMatchQuestionsForId
	| typeof createRefreshLocalStoreAction
	| typeof createUpdateFirebaseAction
>
