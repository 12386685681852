import * as React from 'react'
import {
	clientsSortedByDataPoint,
	correlationsWithDataPoint
} from '../../analytics/algorithms'
import GenericReveal from '../GenericReveal'
import { ISharedStepProps } from './utils'

const Income: React.SFC<ISharedStepProps> = (props) => {
	const algorithmResult = clientsSortedByDataPoint(props.state, 'income')
	const correlations = correlationsWithDataPoint(props.state, 'income')
	const sendToPhonesAction = () => {
		const sortedIds = algorithmResult.map((v) => v.client._id)
		props.actions.setPositionsFromSortedIdsAction(sortedIds)
		props.actions.updateFirebaseAction()
	}

	return (
		<GenericReveal
			name="Risico"
			results={algorithmResult}
			correlations={correlations}
			keysToShow={['income']}
			onClick={sendToPhonesAction}
		/>
	)
}

export default Income
