import * as React from 'react'
import {
	clientsSortedByDataPoint,
	correlationsWithDataPoint
} from '../../analytics/algorithms'
import GenericReveal from '../GenericReveal'
import { ISharedStepProps } from './utils'

const Length: React.SFC<ISharedStepProps> = props => {
	const algorithmResult = clientsSortedByDataPoint(
		props.state,
		'length'
	).reverse()
	const correlations = correlationsWithDataPoint(props.state, 'length')
	const sendToPhonesAction = () => {
		const sortedIds = algorithmResult.map(v => v.client._id)
		props.actions.setPositionsFromSortedIdsAction(sortedIds)
		props.actions.updateFirebaseAction()
	}

	return (
		<GenericReveal
			name="Duurdere schoenen"
			results={algorithmResult}
			correlations={correlations}
			keysToShow={['length']}
			onClick={sendToPhonesAction}
		/>
	)
}

export default Length
