import * as React from 'react'
import {
	clientsSortedByDataPoint,
	correlationsWithDataPoint
} from '../../analytics/algorithms'
import GenericReveal from '../GenericReveal'
import { ISharedStepProps } from './utils'

const Ears: React.SFC<ISharedStepProps> = (props) => {
	const algorithmResult = clientsSortedByDataPoint(props.state, 'ears')
	const correlations = correlationsWithDataPoint(props.state, 'ears')
	const sendToPhonesAction = () => {
		const sendTo = algorithmResult.slice(0, 1)
		const users = sendTo.map((v) => v.client)
		props.actions.setStateOfParticipantsTo(users, 'green_screen', 'none')
		props.actions.updateFirebaseAction()
	}

	return (
		<GenericReveal
			name="Oren"
			results={algorithmResult}
			correlations={correlations}
			keysToShow={['ears']}
			profileKeysToShow={['ears']}
			onClick={sendToPhonesAction}
		/>
	)
}

export default Ears
