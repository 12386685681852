import { applyMiddleware, createStore } from 'redux'
import { reducer } from '../reducers'

import { createFirebaseMiddleware } from './firebase'

const FirebaseMiddleware = createFirebaseMiddleware()

declare const process
export function configureStore() {
	return (() => {
		if (process.env.NODE_ENV === 'development' && (window as any).store) {
			return (window as any).store
		}
		const s = createStore(reducer, applyMiddleware(FirebaseMiddleware))
		if (process.env.NODE_ENV === 'development') {
			;(window as any).store = s
		}
		return s
	})()
}
