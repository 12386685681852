import * as React from 'react'
import { connect } from 'react-redux'

import StepManager, { Steps } from '../Steps'

import { IRootState } from '../../reducers'

import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import ErrorBoundary from '../ErrorBoundary'
import styles from './styles.module.scss'

const stepOrder: Steps[] = [
	Steps.LENGTH,
	Steps.EARS,
	Steps.SLOWNESS,
	Steps.SECRETIVE,
	Steps.CONSENT,
	Steps.INFLUENCERS,
	Steps.INFLUENCERS_VOTE,
	Steps.SQUIRREL,
	// Steps.SQUIRRELPHOTO,
	Steps.INCOME,
	Steps.ADAMEVE,
	Steps.HANDSHAKERS,
	Steps.MATCHES
]

interface IApplicationProps extends ReturnType<typeof mapStateToProps> {}

const Application: React.SFC<IApplicationProps> = props => {
	const [idx, setFilter] = React.useState(0)

	React.useEffect(() => {
		if (window.location.hash) {
			const hashLessString = window.location.hash.substring(1)
			const n = parseInt(hashLessString, 10)
			if (!isNaN(n)) {
				setFilter(n)
			}
		}
	}, [])

	const currentStep = stepOrder[idx] || Steps.INVALID

	return (
		<div className={styles.container}>
			{props.refreshing && (
				<div className={styles.spinnerContainer}>
					<Spinner animation="grow" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</div>
			)}
			<Button size="lg" onClick={() => setFilter(idx - 1)}>
				Previous
			</Button>
			<div className={styles.stepManager}>
				<ErrorBoundary>
					<StepManager step={currentStep} />
				</ErrorBoundary>
			</div>
			<Button size="lg" onClick={() => setFilter(idx + 1)}>
				Next
			</Button>
		</div>
	)
}

const mapStateToProps = (state: IRootState) => ({
	refreshing: state.app.refreshing
})

export default connect(mapStateToProps)(Application)
