import * as React from 'react'
import { twoMostSimilar } from '../../analytics/algorithms'
import { getHandshakers } from './Handshakers'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'
import { IClientState } from '../../../../controller/src/types'
import Urn from '../../analytics/Urn'

import { randomElement } from '../..//utils'
import { onlyParticipating, withoutClients } from '../../analytics/utils'
import styles from './styles.module.scss'

const defaultDialogues = [
	[
		['question-if-your-ex-was-here', 'question-describe-my-face'],
		['question-mom-was-here-tell', 'question-three-reasons-get-to-know'],
		['question-holding-back-really-want', 'question-life-a-book-title']
	],
	[
		['question-describe-my-face', 'question-three-reasons-get-to-know'],
		['question-mom-was-here-tell', 'question-looking-for-in-life'],
		[
			'question-never-tell-stranger',
			'question-tell-family-conversation-ended'
		]
	],
	[
		['question-three-reasons-get-to-know', 'question-written-on-tombstone'],
		['question-life-a-book-title', 'question-looking-for-in-life'],
		[
			'question-tell-family-conversation-ended',
			'question-holding-back-really-want'
		]
	]
]

const adamAndEveDialogue = [
	['question-last-people-left', 'question-written-on-tombstone'],
	['question-life-a-book-title', 'question-three-reasons-get-to-know'],
	['question-looking-for-in-life', 'question-create-something-together-what']
]

const Matches: React.SFC<ISharedStepProps> = props => {
	const sendToPhonesAction = () => {
		// create forced matches
		const adamAndEves = twoMostSimilar(props.state)
		const handshakes = getHandshakers(props.state)

		const rest = onlyParticipating(
			withoutClients(props.state, [...adamAndEves, ...handshakes])
		)

		// randomly match the rest
		rest.sort((a, b) => {
			return parseInt(a.profile.age, 10) - parseInt(b.profile.age, 10)
		})

		const restMatches: IClientState[][] = []
		rest.forEach((c, idx) => {
			const index = Math.floor(idx / 2)
			const match = restMatches[index] || []
			restMatches[index] = [...match, c]
		})

		// full matches array
		const matches: IClientState[][] = [
			adamAndEves,
			handshakes,
			...restMatches
		]

		const randomUrn = new Urn(matches.length)
		matches.forEach(match => {
			const position = randomUrn.getNumber()
			match.forEach(c => {
				props.actions.setPositionForId(c._id, position)
				props.actions.setStateForIdKeyAction(c._id, 'match')
			})
		})

		adamAndEves.forEach((client, index) => {
			const questions = adamAndEveDialogue.map(q => q[index])
			props.actions.setMatchQuestionsForId(client._id, questions)
		})

		const handshakeDialog = randomElement(defaultDialogues)
		handshakes.forEach((client, index) => {
			const questions = handshakeDialog.map(q => q[index])
			props.actions.setMatchQuestionsForId(client._id, questions)
		})

		restMatches.forEach(match => {
			const dialogue = randomElement(defaultDialogues)
			match.forEach((client, index) => {
				const questions = dialogue.map(q => q[index] || q[0])
				props.actions.setMatchQuestionsForId(client._id, questions)
			})
		})

		props.actions.updateFirebaseAction()
	}

	return (
		<div className={styles.noTableSlide}>
			<h1>The Matches</h1>
			<Button size="lg" onClick={sendToPhonesAction}>
				Give the rest a match
			</Button>
		</div>
	)
}

export default Matches
