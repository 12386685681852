import * as React from 'react'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'
import { divide, onlyParticipating } from '../../analytics/utils'
import styles from './styles.module.scss'

const Consent: React.SFC<ISharedStepProps> = props => {
	const participating = onlyParticipating(props.state)
	const [didNotRead, didOpen] = divide(participating, v => {
		return v.profile.openedTerms === false
	})
	const [didOpenAndAccepted, didOpenAndDeclined] = divide(didOpen, v => {
		return v.profile.acceptedTerms === true
	})

	const sendToPhonesAction = () => {
		props.actions.setStateOfParticipantsTo(didNotRead, 'red_screen')
		props.actions.setStateOfParticipantsTo(
			didOpenAndAccepted,
			'green_screen'
		)
		props.actions.setStateOfParticipantsTo(
			didOpenAndDeclined,
			'blue_screen'
		)
		props.actions.updateFirebaseAction()
	}

	return (
		<div className={styles.noTableSlide}>
			<h1>Consent</h1>
			<ul>
				<li>
					{didNotRead.length} people did not read the terms of use
					(red)
				</li>
				<li>
					{didOpenAndAccepted.length} people did read the terms of use
					and accepted (green)
				</li>
				<li>
					{didOpenAndDeclined.length} people did read the terms of use
					and declined (blue)
				</li>
			</ul>
			<Button size="lg" onClick={sendToPhonesAction}>
				Split them up
			</Button>
		</div>
	)
}

export default Consent
