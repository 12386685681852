import firebase from 'firebase/app'
import 'firebase/database'

import { Store } from 'redux'
import {
	Action,
	ActionTypes,
	createSetConnectionStateAction,
	createSetRefreshingStateAction,
	createUpdateAction,
	IRootState
} from '../reducers'
import { firebaseSnapshotToClientState } from '../utils'

export function createFirebaseMiddleware() {
	return (store: Store) => {
		initialiseFirebase()
		const database = firebase.database()
		const root = database.ref()
		const users = root.child('users')

		setTimeout(() => {
			// observe connection state
			database.ref('.info/connected').on('value', snapshot => {
				const online = snapshot ? snapshot.val() : true
				store.dispatch(createSetConnectionStateAction(online))
			})
		}, 10)

		return (next: any) => {
			return (action: Action) => {
				if (action.type === ActionTypes.REFRESH_LOCAL_STORE) {
					store.dispatch(createSetRefreshingStateAction(true))
					users.once('value', snapshot => {
						const clientState = firebaseSnapshotToClientState(
							snapshot
						)
						store.dispatch(createUpdateAction(clientState))
						store.dispatch(createSetRefreshingStateAction(false))
					})
				} else if (
					action.type === ActionTypes.UPDATE_FIREBASE_FROM_LOCAL_STORE
				) {
					const state = store.getState() as IRootState
					const data = state.firebase
					const firebaseObj = {}
					data.forEach(client => {
						const { _id, ...rest } = client
						firebaseObj[_id] = rest
					})

					root.child('users').update(firebaseObj, err => {
						if (err) {
							console.error(err)
						}
					})
				}

				// if (action.type === ActionTypes.RESET_CLIENT) {
				// 	const ref = root.child(action.key)
				// 	ref.set({ online: false })
				// } else if (action.type === ActionTypes.SEAT_CLIENT) {
				// 	const ref = root.child(action.key)
				// 	ref.update({ participating: true })
				// } else if (action.type === ActionTypes.MIGRATE_CLIENT) {
				// 	const fromRef = root.child(action.fromKey)
				// 	fromRef.once('value', snapshot => {
				// 		const toRef = root.child(action.toKey)
				// 		toRef.set(snapshot.toJSON())
				// 		fromRef.set({ online: false })
				// 	})
				// }

				next(action)
			}
		}
	}
}

function initialiseFirebase() {
	firebase.initializeApp({
		apiKey: 'AIzaSyBxwZijJcv-TPjr3ePOEasyPD2jojcYH58',
		authDomain: 'social-sorting.firebaseapp.com',
		databaseURL: 'https://social-sorting.firebaseio.com/'
	})
}
