import * as React from 'react'
import { IClientState } from '../../../../controller/src/types'
import { clientsSortedByDataPoint } from '../../analytics/algorithms'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'
import styles from './styles.module.scss'

export const getInfluencers = (state: IClientState[]) => {
	const s = clientsSortedByDataPoint(state, 'influence')
	const oneFifth = Math.ceil(s.length / 5)
	const onlyWithSausageLover = s.filter(
		c => c.client.password === 'sausagelover'
	)
	return onlyWithSausageLover.slice(-oneFifth)
}

const Influencers: React.SFC<ISharedStepProps> = props => {
	const algorithmResult = getInfluencers(props.state)
	const sendToPhonesAction = () => {
		const users = algorithmResult.map(v => v.client)
		props.actions.setStateOfParticipantsTo(users, 'pink_screen', 'none')
		props.actions.updateFirebaseAction()
	}

	return (
		<div className={styles.noTableSlide}>
			<h1>The Influencers:</h1>
			<ul>
				{algorithmResult.map(r => {
					const { username } = r.client
					return <li key={username}>{username}</li>
				})}
			</ul>
			<Button size="lg" onClick={sendToPhonesAction}>
				Give pink screen
			</Button>
		</div>
	)
}

export default Influencers
