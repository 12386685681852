import { IClientState } from '../../controller/src/types'

export function firebaseSnapshotToClientState(
	snapshot: firebase.database.DataSnapshot
): IClientState[] {
	if (!snapshot) {
		return []
	}
	const data = snapshot.val()
	if (!data) {
		return []
	}

	return Object.keys(data).map(key => {
		const client = data[key]
		return { _id: key, ...client }
	})
}

export function randomElement<T>(arr: T[]): T {
	const index = Math.floor(Math.random() * arr.length)
	return arr[index]
}
