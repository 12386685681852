import * as React from 'react'

interface IErrorBoundaryState {
	hasError: boolean
}

class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	componentWillUpdate() {
		if (this.state.hasError) {
			this.setState({ hasError: false })
		}
	}

	componentDidCatch() {
		this.setState({ hasError: true })
	}

	render() {
		if (this.state.hasError) {
			return <h1>Something went wrong.</h1>
		}
		return this.props.children
	}
}

export default ErrorBoundary
