import * as React from 'react'
import { clientsSortedByDataPoint } from '../../analytics/algorithms'
import { ISharedStepProps } from './utils'

import Button from 'react-bootstrap/Button'

import { IClientState } from '../../../../controller/src/types'
import styles from './styles.module.scss'

export const getMostSquirrle = (state: IClientState[]) => {
	const algorithmResult = clientsSortedByDataPoint(state, 'squirrel')
	const sendTo = algorithmResult.slice(0, 1)
	return sendTo.map(v => v.client)
}

const Squirrel: React.SFC<ISharedStepProps> = props => {
	const users = getMostSquirrle(props.state)
	const sendToPhonesAction = () => {
		props.actions.setStateOfParticipantsTo(users, 'yellow_screen', 'none')
		props.actions.updateFirebaseAction()
	}
	const squirrel = users[0]
	return (
		<div className={styles.noTableSlide}>
			<h1>Degene die het het meest verslaafd is aan zijn telefoon</h1>
			<h3>{squirrel ? squirrel.username : `Unknown name`}</h3>
			<Button size="lg" onClick={sendToPhonesAction}>
				Give Yellow screen
			</Button>
		</div>
	)
}

export default Squirrel
