import * as React from 'react'
import { clientsSortedByDataPoint } from '../../analytics/algorithms'
import { ISharedStepProps } from './utils'

import { IClientState } from '../../../../controller/src/types'
import styles from './styles.module.scss'

const Secretive: React.SFC<ISharedStepProps> = props => {
	const algorithmResult = clientsSortedByDataPoint(props.state, 'secretive')
	const idsSortedOnIncome = clientsSortedByDataPoint(
		props.state,
		'income'
	).map(r => r.client._id)

	const mostSecretive = algorithmResult[0].client
	const positionWhileIncome = idsSortedOnIncome.indexOf(mostSecretive._id) + 1

	return (
		<div className={styles.noTableSlide}>
			<h1>Most Secretive</h1>
			<h3>{mostSecretive.username}</h3>
			<h3>Position on income: {positionWhileIncome}</h3>
			<h3>Consent: {termsText(mostSecretive)}</h3>
		</div>
	)
}

function termsText(client: IClientState) {
	const didOpenTerms = client.profile.openedTerms
	const didAcceptTerms = client.profile.acceptedTerms

	if (didOpenTerms === false) {
		return 'Did not read terms'
	} else if (didAcceptTerms) {
		return 'Did read, and also accepted'
	} else {
		return 'Declinded terms'
	}
}

export default Secretive
